import { onMounted, reactive, ref, watch } from 'vue'
import { CLAIM_TYPE, CLAIM_IMAGE_CATEGORY, CLAIM_STATUS } from './type'
import dayjs from '@/utils/dayjs'
import firebase from 'firebase/app'
import { getOrderClaimById, getOrderClaimList } from '@/api/order/order.claim.service'
import { useRoute } from 'vue-router'
import { ClaimDetailResponse, ClaimImage } from '@/api/order/type'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useCourierStore } from '@/pinia/courier/courier.pinia'

type ClaimTypeOptions = {
    value: string
    label: string
}

type UploadParams = {
    category: CLAIM_IMAGE_CATEGORY
    images: File[]|string[]|any
    shopId: string
    orderId: string
}

const constantClaimTypeOption = [
	{
		label: 'พัสดุเสียหาย',
		value: CLAIM_TYPE.DAMAGED_BOX
	},
	{
		label: 'สินค้าภายในได้รับความเสียหาย',
		value: CLAIM_TYPE.DAMAGED_PRODUCT
	},
	{
		label: 'พัสดุสูญหาย',
		value: CLAIM_TYPE.MISSING_PRODUCT
	},
	{
		label: 'ได้รับสินค้าผิด',
		value: CLAIM_TYPE.WRONG_PRODUCT
	},
]

const constantClaimStatus = [
	{
		label: 'เคลมสำเร็จ',
		value: CLAIM_STATUS.APPROVED
	},
	{
		label: 'ระหว่างตรวจสอบเอกสาร',
		value: CLAIM_STATUS.PENDING
	},
	{
		label: 'ระหว่างดำเนินการ',
		value: CLAIM_STATUS.IN_PROGRESS
	},
	{
		label: 'ยื่นเอกสารเพิ่มเติม',
		value: CLAIM_STATUS.ADDITIONAL
	},
	{
		label: 'ถูกปฏิเสธ',
		value: CLAIM_STATUS.REJECTED
	}
]

export const useClaim = () => {
	//
	const route = useRoute()
	const shopStore = useShopStore()
	const courierStore = useCourierStore()
	const isFetchingDetail = ref(false)
	const isFetchingClaimList = ref(false)
	const claimList = ref<any>([])
	const pagination = reactive({
		limit: 10,
		offset: 1,
		totals: 0
	})
	const claimStatusSelect = ref<CLAIM_STATUS|null>(null)
	const claimDetail = ref<ClaimDetailResponse>()
	const claimType = ref(CLAIM_TYPE.DAMAGED_BOX)
	const claimCost = ref<number|undefined>(0)
	const claimTypeOptions = ref<ClaimTypeOptions[]>(constantClaimTypeOption)
	const description = ref('')
	const images = reactive<any>({
		productImage: [],
		productLabel: [],
		soldBill: [],
		damageProduct: [],
		missingProduct: [],
		expectReceiveBox: [],
		other: []
	})
	const imagesError = reactive({
		productImage: '',
		productLabel: '',
		soldBill: '',
		damageProduct: '',
		missingProduct: '',
		expectReceiveBox: '',
	})

	const validateImages = (): Promise<boolean> => {
		clearErrorImages()
		let isError = false
		if (!images.productImage.length){
			imagesError.productImage = 'กรุณาเลือกรูปภาพ'
			isError = true
		}
		if (claimType.value !== CLAIM_TYPE.MISSING_PRODUCT) {
			if (!images.productLabel.length) {
				imagesError.productLabel = 'กรุณาเลือกรูปภาพ'
				isError = true
			}
		}
		if (!images.soldBill.length) {
			imagesError.soldBill = 'กรุณาเลือกรูปภาพ'
			isError = true
		}
		if (claimType.value === CLAIM_TYPE.DAMAGED_BOX || claimType.value === CLAIM_TYPE.DAMAGED_PRODUCT) {
			if (!images.damageProduct.length) {
				imagesError.damageProduct = 'กรุณาเลือกรูปภาพ'
				isError = true
			}
		}
		if (claimType.value === CLAIM_TYPE.MISSING_PRODUCT) {
			if (!images.missingProduct.length) {
				imagesError.missingProduct = 'กรุณาเลือกรูปภาพ'
				isError = true
			}
		}
		if (claimType.value === CLAIM_TYPE.WRONG_PRODUCT) {
			if (!images.expectReceiveBox.length) {
				imagesError.expectReceiveBox = 'กรุณาเลือกรูปภาพ'
				isError = true
			}
		}
		return Promise.resolve(isError)
	}
	const clearErrorImages = () => {
		imagesError.productImage = ''
		imagesError.productLabel = ''
		imagesError.soldBill = ''
		imagesError.damageProduct = ''
		imagesError.missingProduct = ''
		imagesError.expectReceiveBox = ''
	}

	const uploadImageToFirebase = async (params: UploadParams): Promise<any> => {
		// category
		const promiseUpload = params.images.map((image: string|File) => {
			if (!(image instanceof File)) {
				return {
					url: image,
					category: params.category
				}
			}
			// Do Upload
			const [fileName, fileExtension] = (image as any).name.split('.')
			const path = `claim/${params.shopId}/${params.orderId}/${dayjs().unix()}-${fileName}.${fileExtension}`
			const storage = firebase.storage().ref().child(path)
			return storage.put(image).then(async (snapshot) => {
				const downloadUrl = await snapshot.ref.getDownloadURL()
				return {
					url: downloadUrl,
					category: params.category
				}
			})
		})

		const resolveResponse = await Promise.all(promiseUpload)

		return resolveResponse
	}

	const filterImageByType = (claimImages: ClaimImage[]) => {
		images.productImage = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.PRODUCT_IMAGE)
			.map((each) => each.url)
		images.productLabel = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.PRODUCT_LABEL)
			.map((each) => each.url)
		images.soldBill = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.TRANSACTION_IMAGE)
			.map((each) => each.url)
		images.damageProduct = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.DAMAGED_PRODUCT)
			.map((each) => each.url)
		images.missingProduct = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.MISSING_PRODUCT)
			.map((each) => each.url)
		images.expectReceiveBox = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.WRONG_PRODUCT)
			.map((each) => each.url)
		images.other = claimImages
			.filter((each) => each.category === CLAIM_IMAGE_CATEGORY.OTHER)
			.map((each) => each.url)

	}

	const fetchClaimDetail = async () => {
		try {
			isFetchingDetail.value = true
			const orderId = route.params.orderId
			const { data: claimResponse } = await getOrderClaimById(shopStore.selectedShopId as string ,orderId as string)

			if (!claimResponse) return
			claimDetail.value = claimResponse
			description.value = claimResponse.description as string
			claimCost.value = claimResponse.cost
			claimType.value = claimResponse.type || CLAIM_TYPE.DAMAGED_BOX

			filterImageByType(claimResponse.images)
		} catch (error) {
		//
		} finally {
			isFetchingDetail.value = false
		}
	}

	const fetchClaimList = async () => {
		//
		isFetchingClaimList.value = true
		try {
			const response = await getOrderClaimList({ 
				shopId: shopStore.selectedShopId as string, 
				offset: pagination.offset, 
				limit: pagination.limit,
				...claimStatusSelect.value && { status: claimStatusSelect.value }
			})
	
			claimList.value = courierStore.mapCourierWithOrder(response).data
			pagination.totals = response.pagination.totals
		} catch (error) {
			//
		} finally {
			isFetchingClaimList.value = false
		}
	}

	const mapStatus = (status: CLAIM_STATUS) => {
		if (status === CLAIM_STATUS.APPROVED) {
			return {
				label: 'เคลมสำเร็จ',
				tag: 'success'
			}
		}
		if (status === CLAIM_STATUS.PENDING) {
			return {
				label: 'ระหว่างตรวจสอบเอกสาร',
				tag: 'warning'
			}
		}
		if (status === CLAIM_STATUS.IN_PROGRESS) {
			return {
				label: 'ระหว่างดำเนินการ',
				tag: 'primary'
			}
		}
		if (status === CLAIM_STATUS.REJECTED) {
			return {
				label: 'ปฏิเสธ',
				tag: 'danger'
			}
		}
		if (status === CLAIM_STATUS.ADDITIONAL) {
			return {
				label: 'ยื่นเอกสารเพิ่มเติม',
				tag: 'info'
			}
		}
		return {
			label: '-',
			tag: 'warning'
		}
	}

	return {
		isFetchingDetail,
		claimType,
		claimTypeOptions,
		uploadImageToFirebase,
		description,
		claimDetail,
		images,
		fetchClaimDetail,
		claimList,
		constantClaimStatus,
		claimStatusSelect,
		fetchClaimList,
		pagination,
		isFetchingClaimList,
		claimCost,
		mapStatus,
		imagesError,
		validateImages
	}
}