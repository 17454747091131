import { AxiosResponse } from 'axios'
import axios from '../instance'
import { ClaimDetailResponse, ClaimListRequest, SubmitFormPayload } from './type'

export const submitClaimApi = async (payload: SubmitFormPayload): Promise<AxiosResponse> => {

	const { data } = await axios.post(`/claim/${payload.shopId}`, payload)
    
	return data
}

export const getOrderClaimById = async (shopId: string, orderId: string): Promise<AxiosResponse<ClaimDetailResponse>> => {
	
	const response = await axios.get(`/claim/${shopId}/${orderId}`)
    
	return response
}

export const getOrderClaimList = async ({ shopId, limit, offset, status, type }: ClaimListRequest): Promise<any> => {
	
	const { data } = await axios.get(`/claim/${shopId}`, {
		params: {
			limit,
			offset,
			status,
			type,
		}
	})
    
	return data
}
