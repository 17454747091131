export interface IOrderDetail {
    _id:                    string;
    payment:                Payment;
    selectedPaymentChannel: boolean;
    isReconcile:            boolean;
    isFillInCompleted:      boolean;
    commentIds:             any[];
    deleted:                boolean;
    deletedAt:              null;
    discount:               number;
    shopId:                 string;
    courier:                Courier;
    note:                   string;
    products:               ProductElement[];
    customer:               Customer;
    shipment:               Origin;
    cost:                   number;
    weight:                 number;
    price:                  number;
    origin:                 Origin;
    reference:              string;
    status:                 string;
    printLabelStatus:       string;
    createdAt:              Date;
    updatedAt:              Date;
    customField:            CustomField;
    trackingNumber:         string;
    condition:              Condition;
}

export interface Condition {
    isFillInCustomerInfo:    boolean;
    isFillInShipmentAddress: boolean;
    isFillInPayment:         boolean;
}

export interface Courier {
    _id:   string;
    name:  string;
    image: string;
}

export interface CustomField {
    sortCode:        string;
    dstStoreName:    string;
    sortingLineCode: string;
}

export interface Customer {
    name:    string;
    phoneNo: string;
}

export interface Origin {
    desc1:           string;
    provinceCode:    number;
    districtCode:    number;
    subDistrictCode: number;
    zipCode:         number;
    districtName:    string;
    provinceName:    string;
    subDistrictName: string;
    remoteFee?:      number;
}

export interface Payment {
    channel: string;
}

export interface ProductElement {
    _id:     string;
    product: ProductProduct;
    unit:    number;
    price:   number;
    productSet?: [];
}

export interface ProductProduct {
    images: string[];
    types:  string[];
    _id:    string;
    sku:    string;
    weight: number;
    parent: Parent;
    productSet?: [];
}

export interface Parent {
    _id:  string;
    name: string;
    sku:  string;
}

export enum CLAIM_STATUS {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ADDITIONAL = 'ADDITIONAL'
  }
  
export enum CLAIM_TYPE {
    DAMAGED_PRODUCT = 'DAMAGED_PRODUCT',
    WRONG_PRODUCT = 'WRONG_PRODUCT',
    MISSING_PRODUCT = 'MISSING_PRODUCT',
    DAMAGED_BOX = 'DAMAGED_BOX',
  }
  
export enum CLAIM_IMAGE_CATEGORY {
    PRODUCT_IMAGE = 'PRODUCT_IMAGE',
    PRODUCT_LABEL = 'PRODUCT_LABEL',
    TRANSACTION_IMAGE = 'TRANSACTION_IMAGE',
    DAMAGED_PRODUCT = 'DAMAGED_PRODUCT',
    MISSING_PRODUCT = 'MISSING_PRODUCT',
    WRONG_PRODUCT = 'WRONG_PRODUCT',
    OTHER = 'OTHER',
  }
  